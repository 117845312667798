import React from "react";
import { navigate, Link } from "gatsby";
import { handleAuthentication, isBrowser } from "../utils/auth";
import "../components/base.css";

const Callback = () => {
  handleAuthentication();

  //Max-load 5s
  for (let i = 0; i < 5; i++) {
    if (isBrowser) {
      const navigateBack = () => {
        if (localStorage.getItem("isLoggedIn") === "true") {
          navigate("/medlem");
        } else {
        /*
        else if(i == 4){
          navigate("/")
        }
        */
          setTimeout(navigateBack, 1000);
        }
      };
      navigateBack();
    }
  }
  return (
    <div className="callback">
      <h1>Loggar in till Fornbod...</h1>
      <p>
        {" "}
        om detta tar mer än några sekunder kan du behvöva navigera till{" "}
        <Link to="/">Fornbod</Link> manuellt
      </p>
    </div>
  );
};

export default Callback;
